@mixin ratio-box($ratio) {
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    height: 0;
    padding-top: #{((1 / ($ratio)) * 100%)};
  }
}
