.noknok-pixel {
  position: relative;
  font-size: .8rem;
  font-family: $base-font-family;
  font-weight: normal;
  width: 100%;
  display: block;
  // lizzy-specific
  margin-top: $base-padding/2;

  &:before {
    content: 'A Nok Nok Studio website'
  }

  &--right {
    text-align: right;

    &:before {
      content: '';
    }

    &:after {
      content: 'A Nok Nok Studio website';
    }
  }

  span {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    overflow: hidden;
    display: inline-block;
    margin: 0 .55em;
    vertical-align: middle;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: 100% 100%;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBmaWxsPSIjRkVEQzM4IiBkPSJNMCAwaDMwdjMwSDB6Ii8+PGc+PGcgZmlsbD0iIzAwMCI+PHBhdGggZD0iTTggOGgxLjV2Nkg4ek0xMi41IDhIMTR2NmgtMS41eiIvPjxwYXRoIGQ9Ik04IDhoNnYxLjVIOHoiLz48L2c+PGcgZmlsbD0iIzAwMCI+PHBhdGggZD0iTTE2IDhoMS41djZIMTZ6TTIwLjUgOEgyMnY2aC0xLjV6Ii8+PHBhdGggZD0iTTE2IDhoNnYxLjVoLTZ6Ii8+PC9nPjxwYXRoIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLXdpZHRoPSIxLjUiIGQ9Ik04Ljc1IDE2YTYuMjUgNi4yNSAwIDEgMCAxMi41IDAiLz48L2c+PC9nPjwvc3ZnPg==);
      transform: translateX(-100%);
      transform: translate3d(-100%,0,0);
      transition: transform .25s ease-in-out;
    }
  }

  &:hover span:before {
    transform: translateX(0) translateY(0);
    transform: translate3d(0,0,0);
  }

  &--right span {
    left: auto;
    right: 100%;

    &:before {
      transform: translateX(100%);
      transform: translate3d(0,100%,0);
    }
  }
}
