*, *::before, *::after {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  outline: 0;
}

::selection {
  background: $base-selection-color;
  color: #fff;
}

::-moz-selection {
  background: $base-selection-color;
  color: #fff;
}
html, body {
  font: $base-font-size $base-font-family;
  color: $base-font-color;
  background: #fff;
  overflow: hidden;

  @include smmax {
    font-size: 15px !important;
  }

  @media (max-width: 900px) {
    .mobile {
      display: block !important;
      position: fixed;
      color: #000;
      font-size: 2rem;
      top: 0;
      left: 0;
      z-index: 222;
      width: 100%;
      height: 100%;
      background: #fff;
      transform: translate3d(0,0,0);

      &:after {
        content: 'Lizzy Ann Photography';
        position: absolute;
        left: $base-padding*2.1;
        bottom: $base-padding*1.9;
        display: block;
        font: 6.5vw / 1.2em $title-font-family;
        width: 10em;

        @include sm {
          font-size: 4vw;
          left: $base-padding;
          bottom: $base-padding*.8;
        }
      }

      &-content {
        position: absolute;
        top: 100%;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $base-padding*.5;
        transform-origin: top left;
        transform: rotate(-90deg);
        padding-left: $base-padding*2.5;

        p {
          font-size: 1.75rem;
        }
      }

      // .home-cover-letter {
      //   font-size: 6rem;
      //   top: 1rem;
      //   left: 2rem;
      // }

      // .home-cover-letter--a {
      //   top: auto;
      //   bottom: 1.2rem;
      //   right: 2rem;
      //   left: auto;
      //
      //   &:before {
      //     height: auto;
      //   }
      // }

      &-slider {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        scroll-snap-type: x mandatory;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;

        &-wrapper {
          height: 100%;
          width: auto;
          display: flex;
        }
      }

      &-image {
        height: 100%;
        width: 100vw;
        background-size: cover;
        background-position: center;
        flex-shrink: 0;
        scroll-snap-align: start;
      }

      @media(orientation: landscape) {
        &-landscape { display: block }
      }
      @media(orientation: portrait) {
        &-portrait { display: block }
      }
    }
  }
}

.app {
  cursor: none;
  top: 0;
  left: 0;
  background: #fff;
  cursor: none;
}

.wrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  scroll-snap-type: y mandatory;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  cursor: none;
}

* {
  cursor: none;
}
