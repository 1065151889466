.home {

  &-cover {
    position: relative;
    min-height: 100vh;
    padding: $base-padding;
    scroll-snap-align: start;

    &-image {
      position: relative;
      width: 60%;
      margin: 0;
      padding: 0 $base-padding * 1.5;
      margin-top: -20%;
      transform: translate3d(0,$base-padding,0);
      opacity: 0;
      transition: all .5s $ease;

      @at-root .home-cover--loaded & {
        opacity: 1;
        transform: translate3d(0,0,0);
      }

      &:hover {
        z-index: 1;
      }

      &:nth-of-type(2n) {
        width: 60%;
        margin: 0 0 0 40%;
        margin-top: -30%;
      }

      &:first-child {
        width: 70%;
        margin: 0 15%;
        margin-top: 0;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      &-label {
        position: absolute;
        white-space: nowrap;
        transform: rotate(90deg);
        bottom: 0;
        right: $base-padding*1.25;
        transform-origin: bottom right;

      }

      &:first-child &-label {
        top: auto;
        bottom: 100%;
        right: auto;
        left: calc(100% - #{$base-padding*1.25});
        transform-origin: bottom left;
      }
    }

    &-text {
      position: absolute;
      bottom: 0;
      left: 60%;
      right: 0;
      height: 33%;
      padding: $base-padding $base-padding*2 $base-padding 0;
      display: flex;
      align-items: center;
      opacity: 0;
    }

    &-letter {
      position: absolute;
      bottom: $base-padding*2;
      top: $base-padding*2;
      z-index: 5;
      pointer-events: none;
      width: 23vw;

      @include sm {
        width: 15vw;
        bottom: $base-padding;
        top: $base-padding;
      }

      svg {
        display: block;

      }

      &-wrap {
        position: sticky;
        top: $base-padding;
      }

      &-svg {
        position: relative;
        @include ratio-box(203/161);
        width: 100%;

        svg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          path, polygon { fill: currentColor }
        }
      }

      &--l {
        left: $base-padding*2;

        @include sm {
          left: $base-padding;
        }

        .home-cover-letter-svg {
          // animation: ltrans .6s 1s $ease forwards;
          // transform: translate3d(165.5%,calc(87vw - 19.3vw),0);

          @include md {
            animation: ltrans .8s 2s $ease forwards;
            transform: translate3d(237.5%,calc(50vh - 13.67vw),0);
          }
        }
      }

      @include xs {
        @keyframes ltrans {
          0% {
            transform: translate3d(165.5%,calc(87vw - 19.3vw),0);
          }
          100% {
            transform: translate3d(0,0,0);
          }
        }
      }

      @include sm {
        @keyframes ltrans {
          0% {
            transform: translate3d(237.5%,calc(50vh - 13.67vw),0);
          }
          100% {
            transform: translate3d(0,0,0);
          }
        }
      }

      &--a {
        right: $base-padding*2;

        @include sm {
          right: $base-padding;
        }

        .home-cover-letter-svg {
          // animation: atrans .6s 1s $ease forwards;
          // transform: translate3d(-165.5%,calc(-87vw + 19.3vw),0);

          @include md {
            transform: translate3d(-237.5%,calc(-50vh + 13.67vw),0);
            animation: atrans .8s 2s $ease forwards;
          }
        }

        .home-cover-letter-wrap {
          height: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;

          @include md {
            height: calc(100vh - #{$base-padding*2});
          }
        }
      }

      @include smmax {
        @keyframes atrans {
          0% {
            transform: translate3d(-165.5%,calc(-87vw + 19.3vw),0);
          }
          100% {
            transform: translate3d(0,0,0);
          }
        }
      }

      @include md {
        @keyframes atrans {
          0% {
            transform: translate3d(-237.5%,calc(-50vh + 13.67vw),0);
          }
          100% {
            transform: translate3d(0,0,0);
          }
        }
      }
    }
  }
}
