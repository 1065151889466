.cursor {
  position: fixed;
  top: -2rem;
  left: -2rem;
  transform: translate3d(0,0,0);
  width: 4rem;
  height: 4rem;
  pointer-events: none;
  cursor: none;
  z-index: 555;
  background: #000;
  color: #fff;

  @media (hover: none) {
    opacity: 0;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: .075em;
    transform: translate3d(0,0,0);
  }
}
