.nav {
  // font: 900 1.9rem/1em $title-font-family;

  &-hamburger {
    position: fixed;
    top: $base-padding;
    right: $base-padding;
    width: 2rem;
    height: 1.5rem;
    z-index: 444;

    @include xs {
      top: $base-padding*2;
      right: $base-padding*2;
    }

    &:before, &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: .125em * 1.9;
      background: #000;
      transition: all .25s ease;
    }

    &:before {
      transform: translate3d(0,-150%,0);
    }

    &:after {
      transform: translate3d(0,150%,0);
    }
  }

  &-content {
    position: fixed;
    background: #000;
    width: 100%;
    height: 100%;
    z-index: 333;
    color: #fff;
    overflow: hidden;
    transform: translate3d(100%,-100%,0);
    transition: transform .5s $ease;

    @include smmax {
      background: #fff;
      color: #000;
    }

    &-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      padding: $base-padding;
      transform: translate3d(-100%,100%,0);
      transition: transform .5s $ease;

      @include smmax {
        justify-content: center;
        padding: $base-padding*2.5;

        @media(orientation: landscape) {
          // align-items: center;
        }
      }

      @include sm {
        padding: $base-padding*4 $base-padding $base-padding*2.5;
      }
    }

    &-picture {
      position: absolute;
      z-index: 1;
      top: $base-padding;
      left: $base-padding;
      right: $base-padding;
      bottom: $base-padding;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      pointer-events: none;
      transition: opacity .05s $ease;

      @include smmax {
        display: none;
      }
    }

    &-text {
      position: relative;

      @include smmax {
        position: static;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &-info {
        // padding-bottom: $base-padding*2.5;
        // max-width: 24em;
      }

      &-contact {
        position: absolute;
        bottom: $base-padding*1.8;
        left: $base-padding*2.35;

        @include sm {
          bottom: $base-padding*.8;
          left: $base-padding;
        }

        @include smmax {
          @include sm {
            br { display: none }
          }
        }

        @include md {
          bottom: 0;
          left: 0;
        }
      }

      &-contact p {
        font-family: $title-font-family;
        font-size: 6vw;
        line-height: 1em;

        @include xs {
          line-height: 1.2em;
          font-size: 10.5vw;
        }

        a {
          white-space: nowrap;
        }

        strong {
          font-weight: normal;

          @include smmax { display: none }

        }
      }

      &:hover {
        z-index: 2;
      }
    }

    &-text:hover + &-picture {
      opacity: .5;
    }
  }

  &--open &-content {
    transform: translate3d(0,0,0);

    &-container {
      transform: translate3d(0,0,0);
    }
  }

  &--open &-hamburger {
    display: flex;

    @include md {
      &:before, &:after {
        background: #fff;
      }
    }

    &:before {
      transform: translate3d(0,0,0) rotate(45deg);
    }

    &:after {
      transform: translate3d(0,0,0) rotate(-45deg);
    }
  }

  &--open + .cursor {
    background: #fff;
    color: #000;
    mix-blend-mode: difference;
  }
}
