.project {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: $base-padding 0;
  isolation: isolate;
  scroll-snap-align: start;

  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0;
    transition: opacity $speed $ease;
    pointer-events: none;
  }

  &--active &-backdrop {
    opacity: 1;
  }

  &-sets {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    z-index: 2;
    transform: translate3d(0,0,0);

    &--dark { color: #fff }

    &-set {
      position: relative;
      max-height: 100vh;
      opacity: 0;
      transform: translate3d(0,0,0);
      transition: all $speed .25s $ease;

      @at-root .project--passed & {
        transform: translate3d(0,-10%,0);
      }

      .image-title-container, .projects-sets-set-label-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        pointer-events: none;
        max-width: 100%;
        max-height: 100%;
      }

      &-title {
        position: absolute;
        white-space: nowrap;
        transform: rotate(90deg);
        bottom: 0;
        right: $base-padding*-.25;
        margin-left: $base-padding*.5;
        transform-origin: bottom right;

        @at-root .project-sets-set--cover & {
          bottom: $base-padding*.75;
          right: $base-padding*1.25;
          transform-origin: bottom right;
        }
      }

      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-top: 150%;
        max-height: 100%;
      }

      .tns-outer, & > .tns-item {
        position: absolute;
        top: $base-padding;
        left: $base-padding/2;
        bottom: $base-padding;
        right: $base-padding/2;
        cursor: none;
        overflow: hidden;
      }

      &--cover .tns-outer, &--cover > .tns-item {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }


      & > .tns-item &-image {
        height: 100%;
        width: 100%;
      }

      .tns-inner, .tns-slider {
        height: 100% !important;

        .tns-item {
          height: 100%;
        }
      }

      &-image {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        transform: translate3d(0,0,0);
      }

      &--cover img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      // &-cursor {
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   height: 4rem;
      //   width: 4rem;
      //   margin: -2rem;
      //   background: #000;
      //   color: #fff;
      //   transform: translate3d(0,0,0) scale(0) rotate(0deg);
      //
      //   span {
      //     position: absolute;
      //     top: 0;
      //     left: 0;
      //     width: 100%;
      //     height: 100%;
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //     letter-spacing: .075em;
      //     transform: translate3d(0,0,0) scale(2) rotate(0deg);
      //     // transition: all .25s ease;
      //   }
      // }

      // &:hover &-cursor {
      //   transform: translate3d(0,0,0) scale(1) rotate(45deg);
      //
      //   span {
      //     transform: translate3d(0,0,0) scale(1) rotate(-45deg);
      //   }
      // }
    }
  }



  &--active &-sets-set {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}
