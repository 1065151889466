html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*, *::before, *::after {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: 0; }

::selection {
  background: #000;
  color: #fff; }

::-moz-selection {
  background: #000;
  color: #fff; }

html, body {
  font: 20px "kings-caslon-display", Times, serif;
  color: #000;
  background: #fff;
  overflow: hidden; }
  @media (max-width: 812px) {
    html, body {
      font-size: 15px !important; } }
  @media (max-width: 900px) {
    html .mobile, body .mobile {
      display: block !important;
      position: fixed;
      color: #000;
      font-size: 2rem;
      top: 0;
      left: 0;
      z-index: 222;
      width: 100%;
      height: 100%;
      background: #fff;
      transform: translate3d(0, 0, 0); }
      html .mobile:after, body .mobile:after {
        content: 'Lizzy Ann Photography';
        position: absolute;
        left: 7.35vw;
        bottom: 6.65vw;
        display: block;
        font: 6.5vw/1.2em "aktiv-grotesk-extended", "Arial Black", sans-serif;
        width: 10em; } }
    @media (max-width: 900px) and (min-width: 569px) {
      html .mobile:after, body .mobile:after {
        font-size: 4vw;
        left: 3.5vw;
        bottom: 2.8vw; } }
  @media (max-width: 900px) {
      html .mobile-content, body .mobile-content {
        position: absolute;
        top: 100%;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.75vw;
        transform-origin: top left;
        transform: rotate(-90deg);
        padding-left: 8.75vw; }
        html .mobile-content p, body .mobile-content p {
          font-size: 1.75rem; }
      html .mobile-slider, body .mobile-slider {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        scroll-snap-type: x mandatory;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch; }
        html .mobile-slider-wrapper, body .mobile-slider-wrapper {
          height: 100%;
          width: auto;
          display: flex; }
      html .mobile-image, body .mobile-image {
        height: 100%;
        width: 100vw;
        background-size: cover;
        background-position: center;
        flex-shrink: 0;
        scroll-snap-align: start; } }
  @media (max-width: 900px) and (orientation: landscape) {
    html .mobile-landscape, body .mobile-landscape {
      display: block; } }
  @media (max-width: 900px) and (orientation: portrait) {
    html .mobile-portrait, body .mobile-portrait {
      display: block; } }

.app {
  cursor: none;
  top: 0;
  left: 0;
  background: #fff;
  cursor: none; }

.wrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  scroll-snap-type: y mandatory;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  cursor: none; }

* {
  cursor: none; }

.grid {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 1 auto;
  flex-wrap: wrap;
  padding: 0 3.5vw; }
  .grid .grid {
    padding-left: 0;
    padding-right: 0; }
  .no-flexbox::before, .no-flexbox::after {
    content: " ";
    display: table; }
  .no-flexbox::after {
    clear: both; }

[class*='col-'] {
  flex: 0 0 auto; }
  .no-flexbox [class*='col-'] {
    float: left; }

.padding {
  padding: 3.5vw; }
  @media (min-width: 569px) {
    .padding {
      padding: 3.5vw; } }
  .padding--horizontal {
    padding-top: 2.33333vw;
    padding-bottom: 2.33333vw; }
    @media (min-width: 569px) {
      .padding--horizontal {
        padding-top: 3.5vw;
        padding-bottom: 3.5vw; } }

.col-1-1 {
  flex-basis: 100%;
  width: 100%;
  max-width: 100%; }

.col-2-3 {
  flex-basis: 66.66667%;
  width: 66.66667%;
  max-width: 66.66667%; }

.col-1-3 {
  flex-basis: 33.33333%;
  width: 33.33333%;
  max-width: 33.33333%; }

.col-1-2 {
  flex-basis: 50%;
  width: 50%;
  max-width: 50%; }

.col-1-4 {
  flex-basis: 25%;
  width: 25%;
  max-width: 25%; }

.col-3-4 {
  flex-basis: 75%;
  width: 75%;
  max-width: 75%; }

.col-2-5 {
  flex-basis: 40%;
  width: 40%;
  max-width: 40%; }

.col-1-5 {
  flex-basis: 20%;
  width: 20%;
  max-width: 20%; }

.col-1-8 {
  flex-basis: 12.5%;
  width: 12.5%;
  max-width: 12.5%; }
  @media (max-width: 568px) {
    .col-1-8 {
      flex-basis: 12.5%;
      width: 12.5%;
      max-width: 12.5%; } }

@media (max-width: 568px) {
  .col-xs-1-1 {
    flex-basis: 100%;
    width: 100%;
    max-width: 100%; }
  .col-xs-2-3 {
    flex-basis: 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%; }
  .col-xs-1-3 {
    flex-basis: 33.33%;
    width: 33.33333%;
    max-width: 33.33333%; }
  .col-xs-1-2 {
    flex-basis: 50%;
    width: 50%;
    max-width: 50%; }
  .col-xs-1-4 {
    flex-basis: 25%;
    width: 25%;
    max-width: 25%; }
  .col-xs-1-8 {
    flex-basis: 12.5%;
    width: 12.5%;
    max-width: 12.5%; } }

@media (min-width: 569px) {
  .col-sm-1-1 {
    flex-basis: 100%;
    width: 100%;
    max-width: 100%; }
  .col-sm-2-3 {
    flex-basis: 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%; }
  .col-sm-1-3 {
    flex-basis: 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%; }
  .col-sm-1-2 {
    flex-basis: 50%;
    width: 50%;
    max-width: 50%; }
  .col-sm-1-4 {
    flex-basis: 25%;
    width: 25%;
    max-width: 25%; }
  .col-sm-3-4 {
    flex-basis: 75%;
    width: 75%;
    max-width: 75%; }
  .col-sm-1-8 {
    flex-basis: 12.5%;
    width: 12.5%;
    max-width: 12.5%; } }

@media (min-width: 813px) {
  .col-md-1-1 {
    flex-basis: 100%;
    width: 100%;
    max-width: 100%; }
  .col-md-2-3 {
    flex-basis: 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%; }
  .col-md-1-3 {
    flex-basis: 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%; }
  .col-md-1-2 {
    flex-basis: 50%;
    width: 50%;
    max-width: 50%; }
  .col-md-1-4 {
    flex-basis: 25%;
    width: 25%;
    max-width: 25%; }
  .col-md-3-4 {
    flex-basis: 75%;
    width: 75%;
    max-width: 75%; }
  .col-md-1-8 {
    flex-basis: 12.5%;
    width: 12.5%;
    max-width: 12.5%; } }

@media (min-width: 1150px) {
  .col-lg-1-1 {
    flex-basis: 100%;
    width: 100%;
    max-width: 100%; }
  .col-lg-2-3 {
    flex-basis: 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%; }
  .col-lg-1-3 {
    flex-basis: 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%; }
  .col-lg-1-2 {
    flex-basis: 50%;
    width: 50%;
    max-width: 50%; }
  .col-lg-1-4 {
    flex-basis: 25%;
    width: 25%;
    max-width: 25%; }
  .col-lg-3-4 {
    flex-basis: 75%;
    width: 75%;
    max-width: 75%; }
  .col-lg-1-6 {
    flex-basis: 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%; }
  .col-lg-1-5 {
    flex-basis: 20%;
    width: 20%;
    max-width: 20%; }
  .col-lg-2-5 {
    flex-basis: 40%;
    width: 40%;
    max-width: 40%; }
  .col-lg-3-5 {
    flex-basis: 60%;
    width: 60%;
    max-width: 60%; } }

@media (min-width: 1600px) {
  .col-xl-1-1 {
    flex-basis: 100%;
    width: 100%;
    max-width: 100%; }
  .col-xl-2-3 {
    flex-basis: 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%; }
  .col-xl-1-3 {
    flex-basis: 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%; }
  .col-xl-1-2 {
    flex-basis: 50%;
    width: 50%;
    max-width: 50%; }
  .col-xl-1-4 {
    flex-basis: 25%;
    width: 25%;
    max-width: 25%; }
  .col-xl-1-8 {
    flex-basis: 12.5%;
    width: 12.5%;
    max-width: 12.5%; }
  .col-xl-1-6 {
    flex-basis: 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%; } }

h2 {
  font-size: 4.5vw;
  line-height: .9em;
  font-weight: bold;
  margin-bottom: .5em; }

h3 {
  font-family: "aktiv-grotesk-extended", "Arial Black", sans-serif;
  font-size: 9vw;
  line-height: 1em; }

p {
  font-size: 1.5rem; }
  p strong {
    font-family: "aktiv-grotesk-extended", "Arial Black", sans-serif; }

a {
  color: inherit;
  text-decoration: none;
  cursor: none; }

.nav-hamburger {
  position: fixed;
  top: 3.5vw;
  right: 3.5vw;
  width: 2rem;
  height: 1.5rem;
  z-index: 444; }
  @media (max-width: 568px) {
    .nav-hamburger {
      top: 7vw;
      right: 7vw; } }
  .nav-hamburger:before, .nav-hamburger:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 0.2375em;
    background: #000;
    transition: all .25s ease; }
  .nav-hamburger:before {
    transform: translate3d(0, -150%, 0); }
  .nav-hamburger:after {
    transform: translate3d(0, 150%, 0); }

.nav-content {
  position: fixed;
  background: #000;
  width: 100%;
  height: 100%;
  z-index: 333;
  color: #fff;
  overflow: hidden;
  transform: translate3d(100%, -100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1); }
  @media (max-width: 812px) {
    .nav-content {
      background: #fff;
      color: #000; } }
  .nav-content-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 3.5vw;
    transform: translate3d(-100%, 100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1); }
    @media (max-width: 812px) {
      .nav-content-container {
        justify-content: center;
        padding: 8.75vw; } }
    @media (min-width: 569px) {
      .nav-content-container {
        padding: 14vw 3.5vw 8.75vw; } }
  .nav-content-picture {
    position: absolute;
    z-index: 1;
    top: 3.5vw;
    left: 3.5vw;
    right: 3.5vw;
    bottom: 3.5vw;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: none;
    transition: opacity 0.05s cubic-bezier(0.77, 0, 0.175, 1); }
    @media (max-width: 812px) {
      .nav-content-picture {
        display: none; } }
  .nav-content-text {
    position: relative; }
    @media (max-width: 812px) {
      .nav-content-text {
        position: static;
        display: flex;
        flex-direction: column;
        justify-content: space-between; } }
    .nav-content-text-contact {
      position: absolute;
      bottom: 6.3vw;
      left: 8.225vw; }
      @media (min-width: 569px) {
        .nav-content-text-contact {
          bottom: 2.8vw;
          left: 3.5vw; } }

@media (max-width: 812px) and (min-width: 569px) {
  .nav-content-text-contact br {
    display: none; } }
      @media (min-width: 813px) {
        .nav-content-text-contact {
          bottom: 0;
          left: 0; } }
    .nav-content-text-contact p {
      font-family: "aktiv-grotesk-extended", "Arial Black", sans-serif;
      font-size: 6vw;
      line-height: 1em; }
      @media (max-width: 568px) {
        .nav-content-text-contact p {
          line-height: 1.2em;
          font-size: 10.5vw; } }
      .nav-content-text-contact p a {
        white-space: nowrap; }
      .nav-content-text-contact p strong {
        font-weight: normal; }
        @media (max-width: 812px) {
          .nav-content-text-contact p strong {
            display: none; } }
    .nav-content-text:hover {
      z-index: 2; }
  .nav-content-text:hover + .nav-content-picture {
    opacity: .5; }

.nav--open .nav-content {
  transform: translate3d(0, 0, 0); }
  .nav--open .nav-content-container {
    transform: translate3d(0, 0, 0); }

.nav--open .nav-hamburger {
  display: flex; }
  @media (min-width: 813px) {
    .nav--open .nav-hamburger:before, .nav--open .nav-hamburger:after {
      background: #fff; } }
  .nav--open .nav-hamburger:before {
    transform: translate3d(0, 0, 0) rotate(45deg); }
  .nav--open .nav-hamburger:after {
    transform: translate3d(0, 0, 0) rotate(-45deg); }

.nav--open + .cursor {
  background: #fff;
  color: #000;
  mix-blend-mode: difference; }

.cursor {
  position: fixed;
  top: -2rem;
  left: -2rem;
  transform: translate3d(0, 0, 0);
  width: 4rem;
  height: 4rem;
  pointer-events: none;
  cursor: none;
  z-index: 555;
  background: #000;
  color: #fff; }
  @media (hover: none) {
    .cursor {
      opacity: 0; } }
  .cursor span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: .075em;
    transform: translate3d(0, 0, 0); }

.footer {
  position: relative;
  padding: 3.5vw;
  font: 900 1.9rem/1em "aktiv-grotesk-extended", "Arial Black", sans-serif;
  align-items: flex-end;
  z-index: 444;
  user-select: none;
  color: #000;
  scroll-snap-align: end; }
  @supports (mix-blend-mode: difference) {
    .footer {
      color: #fff;
      mix-blend-mode: difference; } }
  .footer h3 span {
    display: inline-block; }
  .footer h3 > span {
    overflow: hidden;
    line-height: 1.4em;
    margin: -.2em 0; }
  .footer h3 span span {
    transition: transform 0.3s 0.55s cubic-bezier(0.77, 0, 0.175, 1);
    transform-origin: bottom left;
    transform: translate3d(0, 100%, 0) rotate(5deg); }
    .footer--show h3 span span {
      transform: translate3d(0, 0, 0) rotate(0deg); }
  .footer h3 span:nth-child(2) span {
    transition-delay: .35s; }
  .footer-nav {
    padding-bottom: 0.7vw;
    text-align: right; }
    .footer-nav li {
      overflow: hidden;
      line-height: 1.4em;
      margin: -.2em 0; }
      .footer-nav li a {
        display: inline-block;
        transition: transform 0.3s 0.25s cubic-bezier(0.77, 0, 0.175, 1);
        transform-origin: bottom right;
        transform: translate3d(0, 100%, 0) rotate(-5deg); }
        .footer--show .footer-nav li a {
          transform: translate3d(0, 0, 0) rotate(0deg); }
      .footer-nav li:nth-child(2) a {
        transition-delay: .325s; }
      .footer-nav li:nth-child(3) a {
        transition-delay: .4s; }

.home-cover {
  position: relative;
  min-height: 100vh;
  padding: 3.5vw;
  scroll-snap-align: start; }
  .home-cover-image {
    position: relative;
    width: 60%;
    margin: 0;
    padding: 0 5.25vw;
    margin-top: -20%;
    transform: translate3d(0, 3.5vw, 0);
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1); }
    .home-cover--loaded .home-cover-image {
      opacity: 1;
      transform: translate3d(0, 0, 0); }
    .home-cover-image:hover {
      z-index: 1; }
    .home-cover-image:nth-of-type(2n) {
      width: 60%;
      margin: 0 0 0 40%;
      margin-top: -30%; }
    .home-cover-image:first-child {
      width: 70%;
      margin: 0 15%;
      margin-top: 0; }
    .home-cover-image img {
      display: block;
      width: 100%;
      height: auto; }
    .home-cover-image-label {
      position: absolute;
      white-space: nowrap;
      transform: rotate(90deg);
      bottom: 0;
      right: 4.375vw;
      transform-origin: bottom right; }
    .home-cover-image:first-child .home-cover-image-label {
      top: auto;
      bottom: 100%;
      right: auto;
      left: calc(100% - 4.375vw);
      transform-origin: bottom left; }
  .home-cover-text {
    position: absolute;
    bottom: 0;
    left: 60%;
    right: 0;
    height: 33%;
    padding: 3.5vw 7vw 3.5vw 0;
    display: flex;
    align-items: center;
    opacity: 0; }
  .home-cover-letter {
    position: absolute;
    bottom: 7vw;
    top: 7vw;
    z-index: 5;
    pointer-events: none;
    width: 23vw; }
    @media (min-width: 569px) {
      .home-cover-letter {
        width: 15vw;
        bottom: 3.5vw;
        top: 3.5vw; } }
    .home-cover-letter svg {
      display: block; }
    .home-cover-letter-wrap {
      position: sticky;
      top: 3.5vw; }
    .home-cover-letter-svg {
      position: relative;
      overflow: hidden;
      width: 100%; }
      .home-cover-letter-svg:before {
        content: "";
        display: block;
        height: 0;
        padding-top: 79.31034%; }
      .home-cover-letter-svg svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
        .home-cover-letter-svg svg path, .home-cover-letter-svg svg polygon {
          fill: currentColor; }
    .home-cover-letter--l {
      left: 7vw; }
      @media (min-width: 569px) {
        .home-cover-letter--l {
          left: 3.5vw; } }
      @media (min-width: 813px) {
        .home-cover-letter--l .home-cover-letter-svg {
          animation: ltrans 0.8s 2s cubic-bezier(0.77, 0, 0.175, 1) forwards;
          transform: translate3d(237.5%, calc(50vh - 13.67vw), 0); } }
    @media (max-width: 568px) {
      @keyframes ltrans {
        0% {
          transform: translate3d(165.5%, calc(87vw - 19.3vw), 0); }
        100% {
          transform: translate3d(0, 0, 0); } } }
    @media (min-width: 569px) {
      @keyframes ltrans {
        0% {
          transform: translate3d(237.5%, calc(50vh - 13.67vw), 0); }
        100% {
          transform: translate3d(0, 0, 0); } } }
    .home-cover-letter--a {
      right: 7vw; }
      @media (min-width: 569px) {
        .home-cover-letter--a {
          right: 3.5vw; } }
      @media (min-width: 813px) {
        .home-cover-letter--a .home-cover-letter-svg {
          transform: translate3d(-237.5%, calc(-50vh + 13.67vw), 0);
          animation: atrans 0.8s 2s cubic-bezier(0.77, 0, 0.175, 1) forwards; } }
      .home-cover-letter--a .home-cover-letter-wrap {
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end; }
        @media (min-width: 813px) {
          .home-cover-letter--a .home-cover-letter-wrap {
            height: calc(100vh - 7vw); } }
    @media (max-width: 812px) {
      @keyframes atrans {
        0% {
          transform: translate3d(-165.5%, calc(-87vw + 19.3vw), 0); }
        100% {
          transform: translate3d(0, 0, 0); } } }
    @media (min-width: 813px) {
      @keyframes atrans {
        0% {
          transform: translate3d(-237.5%, calc(-50vh + 13.67vw), 0); }
        100% {
          transform: translate3d(0, 0, 0); } } }

.project {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3.5vw 0;
  isolation: isolate;
  scroll-snap-align: start; }
  .project-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0;
    transition: opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
    pointer-events: none; }
  .project--active .project-backdrop {
    opacity: 1; }
  .project-sets {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    z-index: 2;
    transform: translate3d(0, 0, 0); }
    .project-sets--dark {
      color: #fff; }
    .project-sets-set {
      position: relative;
      max-height: 100vh;
      opacity: 0;
      transform: translate3d(0, 0, 0);
      transition: all 0.6s 0.25s cubic-bezier(0.77, 0, 0.175, 1); }
      .project--passed .project-sets-set {
        transform: translate3d(0, -10%, 0); }
      .project-sets-set .image-title-container, .project-sets-set .projects-sets-set-label-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        max-width: 100%;
        max-height: 100%; }
      .project-sets-set-title {
        position: absolute;
        white-space: nowrap;
        transform: rotate(90deg);
        bottom: 0;
        right: -0.875vw;
        margin-left: 1.75vw;
        transform-origin: bottom right; }
        .project-sets-set--cover .project-sets-set-title {
          bottom: 2.625vw;
          right: 4.375vw;
          transform-origin: bottom right; }
      .project-sets-set:before {
        content: '';
        display: block;
        width: 100%;
        padding-top: 150%;
        max-height: 100%; }
      .project-sets-set .tns-outer, .project-sets-set > .tns-item {
        position: absolute;
        top: 3.5vw;
        left: 1.75vw;
        bottom: 3.5vw;
        right: 1.75vw;
        cursor: none;
        overflow: hidden; }
      .project-sets-set--cover .tns-outer, .project-sets-set--cover > .tns-item {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0; }
      .project-sets-set > .tns-item .project-sets-set-image {
        height: 100%;
        width: 100%; }
      .project-sets-set .tns-inner, .project-sets-set .tns-slider {
        height: 100% !important; }
        .project-sets-set .tns-inner .tns-item, .project-sets-set .tns-slider .tns-item {
          height: 100%; }
      .project-sets-set-image {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center; }
      .project-sets-set img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        transform: translate3d(0, 0, 0); }
      .project-sets-set--cover img {
        object-fit: cover;
        width: 100%;
        height: 100%; }
  .project--active .project-sets-set {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

.tns-outer {
  padding: 0 !important; }
  .tns-outer [hidden] {
    display: none !important; }
  .tns-outer [aria-controls], .tns-outer [data-action] {
    cursor: pointer; }

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s; }
  .tns-slider > .tns-item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

.tns-horizontal.tns-subpixel {
  white-space: nowrap; }
  .tns-horizontal.tns-subpixel > .tns-item {
    display: inline-block;
    vertical-align: top;
    white-space: normal; }

.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both; }

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
  margin-right: -100%; }

.tns-no-calc {
  position: relative;
  left: 0; }

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px; }
  .tns-gallery > .tns-item {
    position: absolute;
    left: -100%;
    -webkit-transition: transform 0s, opacity 0s;
    -moz-transition: transform 0s, opacity 0s;
    transition: transform 0s, opacity 0s; }
  .tns-gallery > .tns-slide-active {
    position: relative;
    left: unset !important; }
  .tns-gallery > .tns-moving {
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    transition: all 0.25s; }

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6; }
  .tns-lazy-img.loaded {
    opacity: 1; }

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s; }

.tns-ovh {
  overflow: hidden; }

.tns-visually-hidden {
  position: absolute;
  left: -10000em; }

.tns-transparent {
  opacity: 0;
  visibility: hidden; }

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0; }

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1; }

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden; }

.tns-t-ct {
  width: 2333.33333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0; }
  .tns-t-ct:after {
    content: '';
    display: table;
    clear: both; }
  .tns-t-ct > div {
    width: 1.42857%;
    width: -webkit-calc(100% / 70);
    width: -moz-calc(100% / 70);
    width: calc(100% / 70);
    height: 10px;
    float: left; }

.noknok-pixel {
  position: relative;
  font-size: .8rem;
  font-family: "kings-caslon-display", Times, serif;
  font-weight: normal;
  width: 100%;
  display: block;
  margin-top: 1.75vw; }
  .noknok-pixel:before {
    content: 'A Nok Nok Studio website'; }
  .noknok-pixel--right {
    text-align: right; }
    .noknok-pixel--right:before {
      content: ''; }
    .noknok-pixel--right:after {
      content: 'A Nok Nok Studio website'; }
  .noknok-pixel span {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    overflow: hidden;
    display: inline-block;
    margin: 0 .55em;
    vertical-align: middle; }
    .noknok-pixel span:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: 100% 100%;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBmaWxsPSIjRkVEQzM4IiBkPSJNMCAwaDMwdjMwSDB6Ii8+PGc+PGcgZmlsbD0iIzAwMCI+PHBhdGggZD0iTTggOGgxLjV2Nkg4ek0xMi41IDhIMTR2NmgtMS41eiIvPjxwYXRoIGQ9Ik04IDhoNnYxLjVIOHoiLz48L2c+PGcgZmlsbD0iIzAwMCI+PHBhdGggZD0iTTE2IDhoMS41djZIMTZ6TTIwLjUgOEgyMnY2aC0xLjV6Ii8+PHBhdGggZD0iTTE2IDhoNnYxLjVoLTZ6Ii8+PC9nPjxwYXRoIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLXdpZHRoPSIxLjUiIGQ9Ik04Ljc1IDE2YTYuMjUgNi4yNSAwIDEgMCAxMi41IDAiLz48L2c+PC9nPjwvc3ZnPg==);
      transform: translateX(-100%);
      transform: translate3d(-100%, 0, 0);
      transition: transform .25s ease-in-out; }
  .noknok-pixel:hover span:before {
    transform: translateX(0) translateY(0);
    transform: translate3d(0, 0, 0); }
  .noknok-pixel--right span {
    left: auto;
    right: 100%; }
    .noknok-pixel--right span:before {
      transform: translateX(100%);
      transform: translate3d(0, 100%, 0); }
