// @font-face {
//     font-family: 'Druk';
//     src: url('../fonts/DrukEline-Bold.eot');
//     src: url('../fonts/DrukEline-Bold.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/DrukEline-Bold.woff2') format('woff2'),
//         url('../fonts/DrukEline-Bold.woff') format('woff');
//     font-style: normal;
// }

h2 {
  font-size: 4.5vw;
  line-height: .9em;
  font-weight: bold;
  margin-bottom: .5em;
}

h3 {
  font-family: $title-font-family;
  font-size: 9vw;
  line-height: 1em;
}

p {
  font-size: 1.5rem;

  strong {
    font-family: $title-font-family;
  }
}

a {
  color: inherit;
  text-decoration: none;
  cursor: none;
}
