// colors

// base
$base-font-size: 20px;
$base-selection-color: #000;
$base-font-color: #000;
$base-padding: 3.5vw;
$base-font-family: "kings-caslon-display", Times, serif;
$title-font-family: "aktiv-grotesk-extended", "Arial Black", sans-serif;

// sizes
$size-xs: 568px;
$size-md: 813px;
$size-lg: 1150px;
$size-xl: 1600px;

// transition
$speed: .6s;
$ease: cubic-bezier(0.77, 0, 0.175, 1);
