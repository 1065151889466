.footer {
  position: relative;
  padding: $base-padding;
  font: 900 1.9rem/1em $title-font-family;
  align-items: flex-end;
  z-index: 444;
  user-select: none;
  color: #000;
  scroll-snap-align: end;

  @supports(mix-blend-mode: difference) {
    color: #fff;
    mix-blend-mode: difference;
  }

  h3 {
    span {
      display: inline-block;
    }

    & > span {
      overflow: hidden;
      line-height: 1.4em;
      margin: -.2em 0;
    }

    span span {
      transition: transform .3s .55s $ease;
      transform-origin: bottom left;
      transform: translate3d(0,100%,0) rotate(5deg);

      @at-root .footer--show h3 span span {
        transform: translate3d(0,0,0) rotate(0deg);
      }
    }

    span:nth-child(2) span {
      transition-delay: .35s;
    }
  }


  &-nav {
    padding-bottom: $base-padding*.2;
    text-align: right;

    li {
      overflow: hidden;
      line-height: 1.4em;
      margin: -.2em 0;

      a {
        display: inline-block;
        transition: transform .3s .25s $ease;
        transform-origin: bottom right;
        transform: translate3d(0,100%,0) rotate(-5deg);

        @at-root .footer--show & {
          transform: translate3d(0,0,0) rotate(0deg);
        }
      }

      &:nth-child(2) a {
        transition-delay: .325s;
      }
      &:nth-child(3) a {
        transition-delay: .4s;
      }
    }
  }
}
